import React from "react";
import { PageWrapper, Background, Header } from "../components";
import { DefaultLayout } from "../layouts";
import { white } from "../utils";

export default ({ data }) => (
    <DefaultLayout title="About Us">
        <Background image={data.logo.publicURL}>
            <Header color={white}>Page Not Found</Header>
        </Background>
        <Background>
        </Background>
        <PageWrapper>
                <a style={{paddingLeft: '30px'}} href="/">Return home</a>
        </PageWrapper>
    </DefaultLayout>)

export const query = graphql`
  {
    logo: file(name: { eq: "our-funds" }) {
      publicURL
    }
  }
`;